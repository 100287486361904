import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
state: {
  showCookies: true,
},
mutations: {
  disableShowCookies(state) {
    state.showCookies = false;
  }
},
actions: {

},
getters: {
  getterShowCookies(state) {
    return state.showCookies;
  },
}
})