<template>
  <div id="app">
    <router-link to="/" id="logo">
      <img :src="require('./assets/images/pkw-logo-klein-01.svg')" alt="Praxis Kollektiv Wollzeile Logo">
    </router-link>

    <Navigation/>

    <transition appear mode="out-in" name="fade">
      <router-view class="view" />
    </transition>

    <transition appear mode="out-in" name="fade-slide">
      <CookieNotification v-if="localShowCookies" />
    </transition>

  </div>
</template>

<script>
  
  import Navigation from '@/components/Navigation.vue'
  import CookieNotification from '@/components/CookieNotification.vue'

  export default {
    name: 'PRAXIS_KOLLEKTIV_WOLLZEILE',
    components: {
      Navigation,
      CookieNotification,
    },
    metaInfo: {
      htmlAttrs: {
        lang: 'de',
      },
    },
    computed: {
      localShowCookies() {
        return this.$store.getters.getterShowCookies;
      }
    },
  }

</script>

<style lang="scss">

  @import './assets/css/fonts.css';
  @import './assets/css/layout.scss';
  @import './assets/css/typography.scss';

  .holder-comp {
      // width: 130vh;
      max-width: 100%;
      padding: var(--spacer-responsive-top-05) calc(var(--spacer-responsive-03)*2) 0 calc(var(--spacer-responsive-03)*2);
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;

      @media screen and (min-width: 1000px) {
        padding: var(--spacer-responsive-top-05) calc(var(--spacer-responsive-03)*4) 0 calc(var(--spacer-responsive-03)*4);
      }
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }




  .fade-slide-enter-active,
  .fade-slide-leave-active {
    transition: opacity .5s ease,
                transform .5s ease;
  }

  .fade-slide-enter-active {
    transition-delay: 1s;
  }

  .fade-slide-enter,
  .fade-slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0 !important;
    transform: translateY(var(--spacer-responsive-04));
  }



  html,
  body {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;

    overflow-x: hidden;
    width: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;

    color: var(--color-ink-05);
    font-size: var(--font-size-p);
    
    font-family: "pkw-serif Book";
  }

  html {
    background-color: var(--color-ink-10);
  }

  body {
    background-color: white;
    position: relative;
    overflow-x: hidden;
  }

  #app {
    display: flex;
    justify-content: center;
  }

  #logo {
    z-index: 20;
    position: fixed;
    top: var(--spacer-responsive-04);
    left: var(--spacer-responsive-04);
    width: 50px;
    height: 50px;

    img {
      opacity: .95;
    }

    @media screen and (hover: hover) and (pointer: fine) {
      width: 110px;
      height: 110px;
    }

    @media screen and (min-width: 1000px) {
      width: 110px;
      height: 110px;
    }
  }

</style>
