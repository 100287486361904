<template>
  <div class="footer">

    <div class="footer-item footer-names">
      <span class="bold">PRAXIS KOLLEKTIV WOLLZEILE</span>
      <span>Praxisgemeinschaft für Psychotherapie, Psychologie und Psychiatrie</span>
      <span>© Amila Softić</span>
    </div>

    <div class="footer-item footer-contact">
      <a href="https://www.google.com/maps/place/Wollzeile+31%2F13,+1010+Wien/@48.208051,16.3757197,17z/data=!3m1!4b1!4m5!3m4!1s0x476d079fde75675b:0x7da39205fce9b0d2!8m2!3d48.208051!4d16.3779084" target="_blank">Wollzeile 31/13, 1010 Wien</a>
      <a href="tel:+43 699 170 87 950">+43 699 170 87 950</a>
      <a href="mailto:a.softic@praxiskollektivwollzeile.at">a.softic@praxiskollektivwollzeile.at</a>
    </div>

    <div class="footer-item footer-legal">
      <router-link to="/impressum">Impressum</router-link>
      <router-link to="/datenschutz">Datenschutz</router-link>
      <!-- <router-link to="/agbs">AGBs</router-link> -->
    </div>

    <News />

  </div>
</template>

<script>
import News from '@/components/News.vue'


export default {
  components: {
    News,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.footer {
  // opacity: .75;
  font-family: "pkw-serif Book";
  line-height: 130%;
  color: var(--color-stone-03);
  padding: var(--spacer-responsive-04);
  box-sizing: border-box;
  margin-top: var(--spacer-responsive-top-05);
  margin-bottom: 5vh;
  text-align: center;
  width: 100%;

  .footer-item-highlight {
    color: var(--color-stone-06);
  }

  @media screen and (min-width: 1000px) {
    font-size: var(--font-size-p-smaller);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4, minmax(1fr, 450px));
    grid-column-gap: var(--spacer-responsive-04);
    margin-bottom: 0;

    * {
      text-align: left;
    }
  }

  .footer-item {
    max-width: 450px;
    margin: 0 auto;

    & > * {
      padding: calc(var(--spacer-pixel-01)*.5);
      // &:last-of-type {
      //   margin-top: auto;
      // }
    }

    &:not(:last-of-type) {
      margin-bottom: var(--spacer-pixel-02);
      @media screen and (min-width: 1000px) {
        margin-bottom: 0;
      }
    }
  }

  .footer-contact,
  .footer-legal,
  .footer-names {

    a {
      display: block;
      color: inherit;
      font-style: italic;
      letter-spacing: .02em;

      &:after {
        content: " →";
        font-style: normal;
      }
    }

    span {
      display: inline-block;
    }
  }
}

</style>
