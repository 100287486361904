<template>
    <div id="navigation">

      <div class="navigation-button">
        <span class="navigation-item" @click="isNavigationOpen = !isNavigationOpen">Menü</span>
      </div>

      <div class="navigation-open" :class="{ opened: !isNavigationOpen }">

        <router-link  @click.native="toggleNavigationState()"
                      to="/"
                      class="navigation-item">
                        Über uns
        </router-link>

        <router-link  @click.native="toggleNavigationState()"
                      to="/praxisraume"
                      class="navigation-item">
                        Praxisräume
        </router-link>

        <router-link  @click.native="toggleNavigationState()"
                      to="/das-kollektiv"
                      class="navigation-item">
                        Das Kollektiv
        </router-link>

        <router-link  @click.native="toggleNavigationState()"
                      to="/kontakt-und-lage"
                      class="navigation-item">
                        Kontakt & Lage
        </router-link>

        <router-link  @click.native="toggleNavigationState()"
                      to="/kollektiv-beitreten"
                      class="navigation-item">
                        Kollektiv beitreten
        </router-link>

      </div>
    </div>
</template>

<script>

  export default {
    name: 'Navigation',
    data() {
      return {
        isNavigationOpen: false,
      }
    },
    methods: {
      toggleNavigationState() {
        this.isNavigationOpen = !this.isNavigationOpen
      }
    },
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  :root {
    --navigation-width: 300px;
  }

  @media screen and (max-width: 1000px ) and (hover: none) and (pointer: coarse) {

    #navigation {
      flex-direction: column;
      z-index: 20;
      pointer-events: none;
    }

    .navigation-open {
      transition: margin-right .3s ease;
      pointer-events: all !important;
    }

    .navigation-open.opened {
      margin-right: calc( -100% - var(--spacer-responsive-04) );
      pointer-events: none !important;
    }

    #navigation .navigation-button {
      display: inline-block;
      pointer-events: all;
    }

    .navigation-open {
      display: flex;
      flex-direction: column;
      margin-top: calc(var(--spacer-pixel-01) / 1);
    }

    #navigation .navigation-item {
      margin-left: auto;
    }
  }

  @media screen and (min-width: 1000px ) and (hover: none) and (pointer: coarse) {

    #navigation {
      flex-direction: row;
    }

    .navigation-open {
      flex-wrap: wrap;
      justify-content: flex-end;
      width: var(--navigation-width);
    }

    #navigation .navigation-button {
      display: none;
    }

    #navigation .navigation-item:hover {
      background-color: var(--color-stone-02);
    }

    #navigation .navigation-item {
      margin-left: calc(var(--spacer-pixel-01) / 1);
    }
    
  }

  @media screen and (hover: hover) and (pointer: fine) {

    #navigation {
      flex-direction: row;
    }

    .navigation-open {
      flex-wrap: wrap;
      justify-content: flex-end;
      width: var(--navigation-width);
    }

    #navigation .navigation-button {
      display: none;
    }

    #navigation .navigation-item:hover {
      background-color: var(--color-stone-02);
    }

    #navigation .navigation-item {
      margin-left: calc(var(--spacer-pixel-01) / 1);
    }

  }

  #navigation {
    display: flex;
    z-index: 20;
    text-align: right;
    position: fixed;
    top: var(--spacer-responsive-04);
    right: var(--spacer-responsive-04);
    // font-size: calc(var(--font-size-p)*.875);
    // letter-spacing: .01em;
    // font-weight: bold;

    @media screen and (min-width: 1000px) {
      font-size: var(--font-size-p-smaller);
    }
  }

  #navigation .navigation-item {
    display: inline-block;
    background-color: var(--color-stone-02-alpha);
    color: white;
    padding: calc(var(--spacer-pixel-01) / 2) var(--spacer-pixel-01);
    margin-bottom: calc(var(--spacer-pixel-01) / 1);
    text-decoration: none;
    transition: background-color .3s ease;

  }

  #navigation .navigation-item.router-link-exact-active {
    background-color: var(--color-stone-03);
    opacity: .95;
    // text-decoration: underline;
  }

</style>
