<template>
  <div class="cookie-notification">
    <!-- <h5 class="headline-keyword">Cookies</h5> -->
    <!-- <div class="button-close">×</div> -->
    <h3 class="headline-ambient">Cookies</h3>
    <p class="copytext">
      Diese Website benutzt Cookies. Wenn Sie die Website weiterhin besuchen, gehen wir von Ihrem Einverständnis aus.
    </p>
    <div class="button-accept" v-on:click="hideCookies">Ich habe verstanden</div>
  </div>
</template>

<script>
export default {
  name: 'CookieNotification',
  data() {
    return {
      showCookies: true,
    }
  },
  computed: {
    localShowCookies() {
      return this.$store.getters.getterShowCookies;
    }
  },
  methods: {
    hideCookies() {
      this.showCookies = false;
      this.$store.commit('disableShowCookies');
      localStorage.setItem("cookieconsent", "ok");
    }
  },
  beforeMount() {
    if (localStorage.getItem("cookieconsent")) {
      this.showCookies = false;
      this.$store.commit('disableShowCookies');
      document.body.classList.add("cookieconsent")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.cookie-notification {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 20;
  max-width: 100%;
  padding: calc(var(--spacer-responsive-04) * .95) var(--spacer-responsive-04) var(--spacer-responsive-04) var(--spacer-responsive-04);
  margin: var(--spacer-responsive-04);

  background-color: var(--color-ink-10);
  opacity: .98;
  color: white;

  @media screen and (min-width: 1000px) {
    max-width: 450px;
  }

  .cookieconsent & {
    display: none;
  }

  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .headline-ambient {
    margin-top: 0;
  }

  h5 {
    color: white !important;
  }

  .button-close {
    position: absolute;
    font-size: calc(var(--font-size-p) * 1.25);
    line-height: 1rem;
    top: calc(var(--spacer-responsive-04) * .9);
    right: calc(var(--spacer-responsive-04) * 1);
  }

  .button-accept {
    display: inline-block;
    padding: calc(var(--spacer-responsive-04) * .5);
    margin-top: calc(var(--spacer-responsive-04) * .5);
    width: 100%;
    max-width: 100%;
    text-align: center;
    border: 1px white solid;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
  }
}

</style>
