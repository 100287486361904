<template>
  <div  class="footer-item footer-item-highlight footer-names footer-news"
        v-if="this.story.content.title">
    <span class="bold">ANKÜNDIGUNG</span>
    <span class="footer-news-title">{{ this.story.content.title }}</span>
    <span>{{ this.story.content.copytext }}</span>
    <div>
      <span>{{ this.story.content.date }}</span><span class="time" v-if="this.story.content.time"> um {{ this.story.content.time }}</span>
    </div>
    
  </div>
</template>

<script>
// 1. Require the Storyblok client
import StoryblokClient from 'storyblok-js-client'

// 2. Set your token - you will be able to create a new space later.
const token = 'cBmyIDJ7tqjDjQuQXoB3gQtt';

// 3. Initialize the client with the preview token so we can access our API easily
// from your space dashboard at https://app.storyblok.com
let storyapi = new StoryblokClient({
  accessToken: token
})

export default {
  data () {
    return {
      space: " ",
      story: {
        content: {
          body: []
        }
      },
    }
  },
  methods: {
    getStory(slug, version) {
      storyapi.get('cdn/stories/' + slug, {
        version: version
      })
      .then((response) => {
        this.story = response.data.story
        // this.loaded = true;
      })
      .catch((error) => {
        console.log(error);
      })
    },
  },
  created () {
    // 4. Initialize the Storyblok Client Bridge to allow us to subscribe to events
    // from the editor itself.
    window.storyblok.init({
      accessToken: token
    })
    window.storyblok.on('change', () => {
      // this will indicate to load the home story, exchange that with the full slug of your content
     // either it is the page URL or hardcoded as in the example below
      this.getStory('ankuendigungen', 'draft')
    })
    window.storyblok.pingEditor(() => {
      if (window.storyblok.isInEditor()) {
        this.getStory('ankuendigungen', 'draft')
      } else {
        this.getStory('ankuendigungen', 'published')
      }
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .footer-news {
    .footer-news-title {
      width: 100%;
    }

    .time {
      display: inline;
    }
  }

</style>
