<template>
  <div class="section section-ice">
    
    <img  class="image-texture-background image-full_width"
          :src="this.imageTextureBackground.images[this.imageTextureBackground.images.length -1].path"
          :srcSet="this.imageTextureBackground.srcSet"
          sizes="100vw"
          alt="Wassertextur als Symbol für das Unterbewusste">

    <div class="ice">

      <div class="description-text description-text-top">
        In der Psychotherapie ist der Eisberg ein gängiges Modell um die unbewussten Anteile der Persönlichkeit darzustellen.
      </div>

      <div class="description-text-top-line-holder">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" v-bind:svg-inline="''" v-bind:class="'description-text-line description-text-line-top'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M0 500C52.6 302.6 248.2 74.8 489 0"/></svg>

        <svg  id="description-text-line-top-circle"
              viewBox="0 0 60 60"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="15"/>
        </svg>
      </div>

      <div class="description-text description-text-bottom">
        Denn wir sind nicht nur wandelnde Eisbergspitzen.
      </div>

      <div class="description-text-bottom-line-holder">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" v-bind:svg-inline="''" v-bind:class="'description-text-line description-text-line-bottom'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M0 500C52.6 302.6 248.2 74.8 489 0"/></svg>

        <svg  id="description-text-line-bottom-circle"
              viewBox="0 0 60 60"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="15"/>
        </svg>
      </div>

      <img  id="ice"
            :src="this.imageIce.images[this.imageIce.images.length -1].path"
            :srcSet="this.imageIce.srcSet"
            sizes="100vw"
            alt="Das Eisberg Modell der Psychologie in Anlehnung an Freuds Theorie des Bewusstseins">
    </div>
  </div>
</template>

<script>
import LeaderLine from 'leader-line'
// const LeaderLine = require('leader-line');

export default {
  name: 'SectionIce',
  data() {
    return {
      isNavigationOpen: false,
      imageIce: require('@/assets/images/pkw-ice-01.jpg'),
      imageTextureBackground: require('@/assets/images/pkw-texture-copy-01.jpg'),
      imageTextureForeground: require('@/assets/images/pkw-texture-copy-02.png'),
      uiLine: require('@/assets/ui/pkw-line.svg'),
    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .section.section-ice {
    display: flex;
    align-items: center;
    width: 100vw;
    max-width: 100%;
    /*reset padding to 0*/
    padding: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }

  .section.section-ice .image-texture-background {
    object-fit: cover;
    object-position: 50% 0%;
    margin-bottom: -100vh;
    height: 100vh;
    overflow: visible;
  }

  .section.section-ice .image-texture-foreground {
    object-fit: cover;
    object-position: 50% 0%;

    display: block;
    mix-blend-mode: multiply;
    z-index: 5;

    transform: translateY(1vh) rotate(.3232deg) scale(1.1);
  }

  @supports (mix-blend-mode: multiply) {
    .section.section-ice .image-texture-foreground {
      display: block;
      mix-blend-mode: multiply;
    }
  }

  .ice {
    position: relative;
    display: flex;
    justify-content: center;
  }



  .description-text.description-text-top {
    z-index: 0;

    padding: var(--spacer-pixel-01);
    font-style: italic;
    font-size: calc(var(--font-size-p) * 0.85);
    line-height: 135%;

    background-color: white;
    width: 33ch;

    position: absolute;
    /*left: 360px;*/
    left: calc(var(--text-offset) + 1ch);
    right: 0;
    margin-left: auto;
    margin-right: auto;

    z-index: 10;

    top: -24.5vh;

    animation-name: iceberg-text-top;
    animation-duration: 9s;
    animation-delay: .5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .description-text-top-line-holder {
    position: absolute;
    overflow: visible;

    top: -13vh;
    left: var(--text-offset);
    right: 0;

    width: calc(5ch + 1vw);
    height: calc(5ch + 1vw);

    margin-left: auto;
    margin-right: auto;

    animation-name: iceberg-text-top-line;
    animation-duration: 9s;
    animation-delay: .25s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .description-text-line {  
    width: calc(5ch + 1vw);
    height: calc(5ch + 1vw);
  }

  #description-text-line-top-circle {
    width: calc(2ch + .5vw);
    height: calc(2ch + .5vw);

    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
  }

  #description-text-line-top-circle circle {
    width: 100%;
    height: 100%;
    stroke-width: 1px;
    stroke: var(--color-ink-05);
    z-index: 1;
    fill: white;

    vector-effect: non-scaling-stroke;
  }

  .description-text-line path {
    stroke-width: 1px;
    stroke: var(--color-ink-05);
    z-index: 1;
    fill: transparent;

    vector-effect: non-scaling-stroke;
  }

  @media screen and (max-width: 1000px) {
    .description-text.description-text-top {
      left: 0;
    }

    .description-text-top-line-holder {
      left: 0;
    }
  }

  .description-text.description-text-bottom {
    z-index: 10;

    padding: var(--spacer-pixel-01);
    font-style: italic;
    font-size: calc(var(--font-size-p) * 0.85);
    line-height: 140%;

    background-color: white;
    width: 22ch;

    position: absolute;
    left: 0;
    right: 20vw;
    margin-left: auto;
    margin-right: auto;

    bottom: -1.5vh;

    animation-name: iceberg-text-bottom;
    animation-duration: 9s;
    animation-delay: .5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .description-text-bottom-line-holder {
    position: absolute;
    overflow: visible;

    left: 0;
    right: 20vw;
    bottom: -3vh;

    width: calc(5ch + 1vw);
    height: calc(5ch + 1vw);

    margin-left: auto;
    margin-right: auto;

    animation-name: iceberg-text-bottom-line;
    animation-duration: 9s;
    animation-delay: .25s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  #description-text-line-bottom-circle {
    width: calc(2ch + .5vw);
    height: calc(2ch + .5vw);

    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-55%, 73%);
  }

  #description-text-line-bottom-circle circle {
    width: 100%;
    height: 100%;
    stroke-width: 1px;
    stroke: var(--color-ink-05);
    z-index: 1;
    fill: transparent;

    vector-effect: non-scaling-stroke;
  }

  .description-text-line path {
    stroke-width: 1px;
    stroke: var(--color-ink-05);
    z-index: 1;
    fill: transparent;

    vector-effect: non-scaling-stroke;
  }

  @media screen and (max-width: 1000px) {
    .description-text.description-text-bottom {
      right: 0;
    }

    .description-text-bottom-line-holder {
      right: 0;
    }
  }

  @media screen and (max-width: 1000px ) {
    .ice:before {
      left: 0;
    }
    .ice:after {
      right: 0;
    }
  }

  .ice-text-bottom {
    display: inline-block;
    width: 150px;

    position: relative;

    right: calc(45% + 10vw);
    bottom: 10vh;
  }

  .section.section-ice .ice img#ice {
    height: 100vh;
    max-width: 100%;
    will-change: transform;
    object-fit: cover;

    margin-top: -10vh;
    margin-bottom: 10vh;

    animation-name: iceberg-only;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;

    mask: url('../assets/images/pkw-ice-maske-01.png');
    mask-size: auto 100%;
    mask-position: center;

    @media screen and (min-width: 1000px) {
      mask-size: 100%;
    }
  }

/*  @media screen and (max-height: 667px ) and (orientation: portrait) {
    .section.section-ice .ice img {
      height: 45vh;
    }
    .section.section-ice .image-texture-background {
      height: 45vh;
    }
  }*/

  @keyframes iceberg-only {
    0% {
      transform: translateY(2vh) translateZ(1px) scaleX(-1);
    }
    50% {
      transform: translateY(-2vh) translateZ(1px) scaleX(-1);
    }
    100% {
      transform: translateY(2vh) translateZ(1px) scaleX(-1);
    }
  }

  @keyframes iceberg-text-top {
    0% {
      transform: translateY(2vh) rotate(-2deg);
    }
    50% {
      transform: translateY(-2vh) rotate(-2deg);
    }
    100% {
      transform: translateY(2vh) rotate(-2deg);
    }
  }

  @keyframes iceberg-text-top-line {
    0% {
      transform: translateY(calc(2vh - 100%)) translateX(calc(-50% - 17ch)) rotate(-2deg);
    }
    50% {
      transform: translateY(calc(-2vh - 100%)) translateX(calc(-50% - 17ch)) rotate(-2deg);
    }
    100% {
      transform: translateY(calc(2vh - 100%)) translateX(calc(-50% - 17ch)) rotate(-2deg);
    }
  }

  @keyframes iceberg-text-bottom-line {
    0% {
      transform: translateY(calc(2vh - 100%)) translateX(calc(50% + 13ch)) rotate(-182deg);
    }
    50% {
      transform: translateY(calc(-2vh - 100%)) translateX(calc(50% + 13ch)) rotate(-182deg);
    }
    100% {
      transform: translateY(calc(2vh - 100%)) translateX(calc(50% + 13ch)) rotate(-182deg);
    }
  }

  @keyframes iceberg-text-bottom {
    0% {
      transform: translateY(2vh) rotate(-4deg);
    }
    50% {
      transform: translateY(-2vh) rotate(-4deg);
    }
    100% {
      transform: translateY(2vh) rotate(-4deg);
    }
  }

  @media screen and (max-width: 1000px ) {
    @keyframes iceberg-text-top-line {
      0% {
        transform: translateY(calc(2vh - 100%)) rotate(-45deg);
      }
      50% {
        transform: translateY(calc(-2vh - 100%)) rotate(-45deg);
      }
      100% {
        transform: translateY(calc(2vh - 100%)) rotate(-45deg);
      }
    }

    @keyframes iceberg-text-bottom-line {
      0% {
        transform: translateY(calc(2vh - 100%)) rotate(-202deg);
      }
      50% {
        transform: translateY(calc(-2vh - 100%)) rotate(-202deg);
      }
      100% {
        transform: translateY(calc(2vh - 100%)) rotate(-202deg);
      }
    }
  }

</style>