import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import StoryblokVue from 'storyblok-vue'
import VueMeta from 'vue-meta'

import Footer from '@/components/Footer.vue'

Vue.component('Footer', Footer)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
}) 

Vue.use(StoryblokVue)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
