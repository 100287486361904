import Vue from 'vue'
import Router from 'vue-router'

import VueAnalytics from 'vue-analytics'

import About from './views/About.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'About',
      component: About
    },
    {
      path: '/praxisraume',
      name: 'rooms',
      // route level code-splitting
      // this generates a separate chunk (rooms.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () { 
        return import(/* webpackChunkName: "rooms" */ './views/Rooms.vue')
      }
    },
    {
      path: '/das-kollektiv',
      name: 'collective',
      // route level code-splitting
      // this generates a separate chunk (collective.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () { 
        return import(/* webpackChunkName: "collective" */ './views/Collective.vue')
      }
    },
    {
      path: '/kontakt-und-lage',
      name: 'contact-and-location',
      // route level code-splitting
      // this generates a separate chunk (contact-and-location.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () { 
        return import(/* webpackChunkName: "contact-and-location" */ './views/ContactLocation.vue')
      }
    },
    {
      path: '/kollektiv-beitreten',
      name: 'join',
      // route level code-splitting
      // this generates a separate chunk (join.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () { 
        return import(/* webpackChunkName: "join" */ './views/Join.vue')
      }
    },
    {
      path: '/impressum',
      name: 'imprint',
      // route level code-splitting
      // this generates a separate chunk (imprint.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () { 
        return import(/* webpackChunkName: "imprint" */ './views/Imprint.vue')
      }
    },
    {
      path: '/datenschutz',
      name: 'privacy',
      // route level code-splitting
      // this generates a separate chunk (privacy.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () { 
        return import(/* webpackChunkName: "privacy" */ './views/Privacy.vue')
      }
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 1000)
    })
  }
})

Vue.use(VueAnalytics, {
  id: 'UA-149446505-2',
  router,
  checkDuplicatedScript: true,
})

export default router;