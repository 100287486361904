<template>
  <div>
    <div class="holder-comp holder-comp-ice">
      <SectionCollective id="landing" class="section-left"/>
      <SectionIce id="ice"/>
      <SectionAbout id="about" class="section-right"/>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SectionCollective from '@/components/SectionCollective.vue'
import SectionIce from '@/components/SectionIce.vue'
import SectionAbout from '@/components/SectionAbout.vue'

export default {
  components: {
    SectionCollective,
    SectionIce,
    SectionAbout,
  },
  metaInfo: {
    title: 'Praxisgemeinschaft für Psychotherapie, Psychologie und Psychiatrie | PRAXIS KOLLEKTIV WOLLZEILE',
    meta: [
      {name: 'description', content: `Die Praxisgemeinschaft PRAXIS KOLLEKTIV WOLLZEILE ist ein Ort der Zusammenkunft von unabhängig arbeitenden PsychotherapeutInnen und BeraterInnen in der Wollzeile 31/13, 1010 Wien.`},
      // Schema.org markup for Google+
      {itemprop: 'description', content: `Die Praxisgemeinschaft PRAXIS KOLLEKTIV WOLLZEILE ist ein Ort der Zusammenkunft von unabhängig arbeitenden PsychotherapeutInnen und BeraterInnen in der Wollzeile 31/13, 1010 Wien.`},
      {name: 'robots', content: `index, follow`}
    ],
  }
}
</script>

<style lang="scss" scoped>
  .holder-comp {
    padding-top: 0; 

    &.holder-comp-ice {
      padding-left: 0;
      padding-right: 0;

      #landing,
      #about {
        padding-left: calc(var(--spacer-responsive-03)*2);
        padding-right: calc(var(--spacer-responsive-03)*2);

        @media screen and (min-width: 1000px) {
          padding-left: calc(var(--spacer-responsive-03)*4);
          padding-right: calc(var(--spacer-responsive-03)*4);
        }
      }
    }
  }
</style>