<template>
  <div class="section section-about">
    <div class="textblock">
      <h2 class="headline-keyword">Über uns</h2>
      <h3 class="headline-ambient">Eisbrechen</h3>
      <p class="copytext">
        Was bewegt uns, so zu denken, zu empfinden, zu handeln wie wir es tun? Diesen Fragen gehen wir im  <router-link to="/das-kollektiv" class="copytext-link">Kollektiv</router-link> auf den Grund. Und bieten Menschen, die nach Selbsterfahrung, persönlicher Entwicklung oder Beistand in der Krise suchen, einen sicheren Therapieraum in 1010 Wien. Die Praxisgemeinschaft ist eine Oase der Geborgenheit im Herzen der Stadt: <router-link to="/kontakt-und-lage" class="copytext-link">Kontaktieren</router-link> Sie uns gerne, wenn Sie psychotherapeutische psychologische oder psychiatrische Begleitung oder Beratung benötigen.
        <br>
        In unserer Gemeinschaftspraxis kann man sich als Psychotherapeut, Psychiater oder Beraterin stunden- oder tageweise einmieten. Doch geht es nicht allein um das Mieten von Praxisräumen, sondern trotz unabhängigen Arbeitens, auch um gemeinsame Werte, interdisziplinäre Synergien und eine <router-link to="/kollektiv-beitreten" class="copytext-link">kultivierte Kollegialität</router-link>.
        <br>
        Die künstlerisch eingerichtete Gemeinschaftspraxis bietet die Ordinationsräume <router-link to="/praxisraume" class="copytext-link">Graustein & Kronjuwel</router-link> und eine vertrauensvolle Atmosphäre, in der man Klienten und Patienten in aller Individualität empfangen und auf ihrem Lebens- und Leidensweg bestmöglich unterstützen kann.
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'SectionAbout',
  data() {
    return {
      // imagePkw: require('@/assets/images/pkw-about-01.jpg')
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
