<template>
  <div class="section section-landing">
    <div class="textblock">
      <h1 class="headline-keyword">Praxisgemeinschaft für Psychotherapie, Psychologie und Psychiatrie</h1>
      <h3 class="headline-ambient">Praxis Kollektiv Wollzeile</h3>
      <p class="copytext">
        Das <span class="small-caps">Praxis Kollektiv Wollzeile</span> ermöglicht eine interdisziplinäre Zusammenkunft von unabhängig arbeitenden ExpertInnen, welche Psychotherapie, Psychologie, Psychiatrie, Coaching und Beratung in gemeinsamen Räumen anbieten und die es sich zur Profession gemacht haben Menschen in ihrer Persönlichkeitsentwicklung sowie in psychischen Krisen zu unterstützen. Darüber hinaus versteht sich das <span class="small-caps">Praxis Kollektiv Wollzeile</span> als Ausstellungsraum für gesellschaftliche Phänomene und zeitgenössische Kunst.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionLanding',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .section.section-landing {
    min-height: 100vh;
    margin-bottom: 0;
    justify-content: center;
    padding-bottom: 0;
    margin-bottom: 0;

    .textblock {
      padding-top: var(--spacer-responsive-top-05);
      padding-bottom: calc(var(--spacer-responsive-top-05)*2);

      @media screen and (min-width: 1000px) {
        padding-top: unset;
        padding-bottom: unset;
      }
    }
  }

</style>
